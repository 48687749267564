import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeolocServiceService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    responseType: 'text'
  };
  constructor(private http: HttpClient) { }

  getLowVoltageLines(zoomLevel,nord,est,sud,ouest): Observable<any> {

    var apiUrl = 'https://opendata.agenceore.fr/api/records/1.0/geopreview/?format=geojson&disjunctive.nom_grd=true&disjunctive.epci=true&disjunctive.departement=true&disjunctive.region=true&clusterprecision=' + zoomLevel + '&geofilter.bbox='+sud  +','+ouest+','+nord+','+est+'&dataset=reseau-aerien-basse-tension-bt&timezone=Europe%2FBerlin&lang=fr';
    console.log(apiUrl);
    return this.http.get(apiUrl );
  }

  getMediumVoltageLines(zoomLevel,nord,est,sud,ouest): Observable<any> {

    var apiUrl = 'https://opendata.agenceore.fr/api/records/1.0/geopreview/?format=geojson&disjunctive.nom_grd=true&disjunctive.epci=true&disjunctive.departement=true&disjunctive.region=true&clusterprecision=' + zoomLevel + '&geofilter.bbox='+sud  +','+ouest+','+nord+','+est+'&dataset=reseau-aerien-moyenne-tension-hta&timezone=Europe%2FBerlin&lang=fr';
    console.log(apiUrl);
    return this.http.get(apiUrl );
  }

  getHighVoltageLines(zoomLevel,nord,est,sud,ouest): Observable<any> {

    var apiUrl = 'https://opendata.agenceore.fr/api/records/1.0/geopreview/?format=geojson&disjunctive.nom_grd=true&disjunctive.epci=true&disjunctive.departement=true&disjunctive.region=true&clusterprecision=' + zoomLevel + '&geofilter.bbox='+sud  +','+ouest+','+nord+','+est+'&dataset=reseau-aerien-haute-tension-htb&timezone=Europe%2FBerlin&lang=fr';
    console.log(apiUrl);
    return this.http.get(apiUrl );
  }
}
