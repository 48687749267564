import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { GeolocServiceService } from '../geoloc-service.service';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { GeoApiFrProvider } from 'leaflet-geosearch';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  constructor(private geolocServiceService:GeolocServiceService) { }

  private map: L.Map;
  public layerGroupLow;
  public layerGroupMedium;
  public layerGroupHigh;
 

  private initMap(): void {
    const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon-2x.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

    this.map = L.map('map').setView([50.554660146201094, 3.095548174152592], 10); // Coordonnées de Paris


    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
    const provider = new OpenStreetMapProvider();
    const provider2 = new GeoApiFrProvider();
    const searchControl = GeoSearchControl({
      marker: {
        icon: iconDefault,
        draggable: false,
      },
      //provider: new OpenStreetMapProvider(),
      popupFormat: ({ query, result }) => result.label+ '<a href="http://maps.google.com/maps?q='+ query.query +'&layer=c&cbll=' + query.data.y + ',' + query.data.x + '&cbp=11,0,0,0,0" target="blank"><b> Google Street View </b></a> ',
      provider : new GeoApiFrProvider(),
      style: 'bar',
      searchLabel: 'Saisir votre adresse'
    });
    
    this.map.addControl(searchControl);

    this.map.on('moveend', (event: L.LeafletEvent) => {
      console.log("ok");
      const zoomLevel = this.map.getZoom();
      const bounds = this.map.getBounds(); // Obtenir les coins de la carte
      const sud = bounds.getSouth(); // Coin sud-ouest
      const nord = bounds.getNorth(); // Coin nord-est
      const est = bounds.getEast();
      const ouest = bounds.getWest();
      console.log(nord);
      console.log(sud);
      console.log(est);
      console.log(ouest);

      this.clearLayerLow();
      this.clearLayerMedium();
      this.clearLayerHigh();
      if(zoomLevel > 13)
      {
        this.callServiceBasseTension(zoomLevel,nord,est,sud,ouest);
        this.callServiceMediumTension(zoomLevel,nord,est,sud,ouest);
        this.callServiceHauteTension(zoomLevel,nord,est,sud,ouest);
      }
      
      


    });

    this.map.on('click', (event: L.LeafletEvent) => {
      let lat = event.latlng.lat.toPrecision(8);
      let lon = event.latlng.lng.toPrecision(8);
      const point = L.marker([lat,lon]).addTo(this.map)
          .bindPopup('<a href="http://maps.google.com/maps?q=&layer=c&cbll=' + lat + ',' + lon + '&cbp=11,0,0,0,0" target="blank"><b> Google Street View </b></a>').openPopup();
  });

   

    
  }

  ngOnInit() {


 
  }

  ngAfterViewInit(): void {
    this.initMap();


    



  }

  private callServiceBasseTension(zoomLevel,nord,est,sud,ouest){
    
    var tab = [];
    this.geolocServiceService.getLowVoltageLines(zoomLevel,nord,est,sud,ouest).subscribe((data: any) => {
      
      data.forEach((result: any) => {
       var line = L.geoJSON(result.geometry, {
          style: {
            color: 'purple', // Couleur de la ligne
            weight: 2,     // Largeur de la ligne
          }
        });
        tab.push(line);
      });

      this.layerGroupLow = L.layerGroup(tab);
      this.layerGroupLow.addTo(this.map);

    }, error => {
      console.error('Erreur lors de la récupération des données de l\'API :', error);
    });

  }

  private callServiceMediumTension(zoomLevel,nord,est,sud,ouest){
    
    var tab = [];
    this.geolocServiceService.getMediumVoltageLines(zoomLevel,nord,est,sud,ouest).subscribe((data: any) => {
      
      data.forEach((result: any) => {
       var line = L.geoJSON(result.geometry, {
          style: {
            color: 'blue', // Couleur de la ligne
            weight: 2,     // Largeur de la ligne
          }
        });
        tab.push(line);
      });

      this.layerGroupMedium = L.layerGroup(tab);
      this.layerGroupMedium.addTo(this.map);

    }, error => {
      console.error('Erreur lors de la récupération des données de l\'API :', error);
    });

  }

  private callServiceHauteTension(zoomLevel,nord,est,sud,ouest){
    
    var tab = [];
    this.geolocServiceService.getHighVoltageLines(zoomLevel,nord,est,sud,ouest).subscribe((data: any) => {
      
      data.forEach((result: any) => {
       var line = L.geoJSON(result.geometry, {
          style: {
            color: 'red', // Couleur de la ligne
            weight: 2,     // Largeur de la ligne
          }
        });
        tab.push(line);
      });

      this.layerGroupHigh = L.layerGroup(tab);
      this.layerGroupHigh.addTo(this.map);

    }, error => {
      console.error('Erreur lors de la récupération des données de l\'API :', error);
    });

  }

  public clearLayerLow(){
    console.log(this.layerGroupLow);
    if(this.layerGroupLow != undefined)
    {
      this.map.removeLayer(this.layerGroupLow);
     this.layerGroupLow = undefined;
    }
  }

  public clearLayerMedium(){
    console.log(this.layerGroupMedium);
    if(this.layerGroupMedium != undefined)
    {
      this.map.removeLayer(this.layerGroupMedium);
     this.layerGroupMedium = undefined;
    }
  }

  public clearLayerHigh(){
    console.log(this.layerGroupHigh);
    if(this.layerGroupHigh != undefined)
    {
      this.map.removeLayer(this.layerGroupHigh);
     this.layerGroupHigh = undefined;
    }
  }

}
